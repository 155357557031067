export const PostType = {
    POST: 1,
    QUESTION: 2,
};

export const PostView = {
    TRENDING: 1,
    LATEST: 2,
    SUBSCRIBED: 3,
}

export const MediaNetworkState = {
    EMPTY: 0,
    IDLE: 1,
    LOADING: 2,
    NO_SOURCE: 3,
}
