<script setup>
import { ref } from 'vue';
import { TabsList, TabsRoot, TabsTrigger, TabsContent, TabsIndicator } from 'radix-vue';

import PostForm from '@/components/Post/PostForm.vue';

defineEmits(['postPublished', 'changeChannel']);

const activeTab = ref('post');

const props = defineProps({
    channel: {
        type: Object,
        required: true,
    }
});
</script>

<template>
    <!-- TABS -->
    <TabsRoot :model-value="activeTab" @update:model-value="(value) => activeTab = value">
        <div class="bg-white text-white-foreground pt-4">
            <TabsList class="relative shrink-0 flex justify-evenly gap-4 border-b border-black/20" aria-label="Navigate Channel">
                <TabsIndicator class="absolute h-[3px] w-[--radix-tabs-indicator-size] left-0 bottom-0 translate-x-[--radix-tabs-indicator-position] rounded-full transition-[width,transform] bg-primary" />

                <TabsTrigger class="items-center justify-center select-none cursor-pointer outline-none py-1 data-[state=active]:text-primary" value="post">
                    Create Post
                </TabsTrigger>
                <TabsTrigger class="items-center justify-center select-none cursor-pointer outline-none py-1 data-[state=active]:text-primary" value="question">
                    Ask Question
                </TabsTrigger>
            </TabsList>
        </div>
        <div class="pt-4 px-4">
            <TabsContent value="post">
                <PostForm @back="$emit('changeChannel')" :channel="channel" @postPublished="(post) => $emit('postPublished', post)" />
            </TabsContent>
            <TabsContent value="question">
                <PostForm @back="$emit('changeChannel')" :channel="channel" question @postPublished="(post) => $emit('postPublished', post)" />
            </TabsContent>
        </div>
    </TabsRoot>
</template>
